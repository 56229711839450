/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { P2, P4 } from 'components/Typography'
import Table, { TableRow } from 'components/Table/Table'
import { useSearchAndFilterTable } from 'contexts/SearchAndFilterTableContext'
import HeaderTable from 'components/Table/HeaderTable'
import Link from 'components/Button/LinkUnderline'
import { useNavigate } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import StatusTag from 'components/Tag/StatusTag'
import { Tooltip } from 'react-tooltip'
import { compressLongText, isLongText, replaceText } from 'utils/textProcess'
import { useWindowWidth } from 'contexts/WindowWidthContext'
import { Button } from 'components/index'

const LinkTable = styled(Link)`
  line-break: anywhere;
`

const CustomHeader = styled(HeaderTable)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  @media (min-width: 440px) and(max-width: 1366px) {
    grid-template-columns: 8% 18% 8% 10% 6% 6% 8% 10% 8%;
  }
`

const CustomTable = styled(Table)`
  ${TableRow} {
    @media (min-width: 440px) and(max-width: 1366px) {
      grid-template-columns: 8% 18% 8% 10% 6% 6% 8% 10% 8%;
    }
  }
`

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SubTitleTable = styled(P2)`
  margin: 15px;
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  text-align: center;
`

const ScrolleableTable = styled.div`
  height: 50vh;
  overflow: auto;
`

const TableCell = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ $precioReal }) => ($precioReal ? 'pointer' : 'initial')};
`

const VerticalTableCell = styled(TableCell)`
  flex-direction: column;
  align-items: start;
  gap: 6px;
`

const CustomStatusTag = styled(StatusTag)`
  font-size: 12px;
`

const PromoTag = styled(CustomStatusTag)`
  background-color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`

const IdCol = styled.div`
  display: block;
`

const isEstadoSuspendido = (estado) => {
  switch (estado) {
    case '1':
      return false // Activo
    case 2:
    case 3:
    case 10:
    case 31:
    default:
      return true // Suspendido
  }
}

const devolverPrecioReal = (articulo) => {
  const precioReal = parseFloat(articulo.precio_real).toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 3,
  })

  return precioReal
}

const formatData = ({ articulos }, navigateToProductoPage, width) =>
  articulos.map((e) => ({
    ...(width > 440 && {
      Categoria: (
        <TableCell>
          <CustomStatusTag
            estado={isEstadoSuspendido(e.estado) ? 'Suspendido' : 'Activo'}
          />
        </TableCell>
      ),
    }),

    Producto: (
      <>
        <VerticalTableCell
          data-tooltip-id={
            isLongText(e.descripcion, 24) ? 'productoDescripcion' : undefined
          }
          data-tooltip-content={
            isLongText(e.descripcion, 24) ? e.descripcion : undefined
          }
          data-tooltip-place="top"
        >
          {e.id_promocion !== '0' && <PromoTag estado={'PROMO'} />}
          <LinkTable onClick={() => navigateToProductoPage(e.id_articulo)}>
            {compressLongText(e.descripcion, 24)}
          </LinkTable>
        </VerticalTableCell>
        {isLongText(e.descripcion, 24) && (
          <Tooltip id="productoDescripcion" delayShow={50} />
        )}
      </>
    ),
    'UNI/MEDIDA': (
      <TableCell>
        <P4>{e.gramaje + ' ' + e.unidad_medida}</P4>
      </TableCell>
    ),
    ...(width > 440 && {
      EAN: (
        <TableCell>
          {e.id_col && e.id_col !== '0' ? (
            <>
              <IdCol>
                <P4>{e.ean13}</P4>
                <P4>({e.id_col})</P4>
              </IdCol>
            </>
          ) : (
            <P4>{e.ean13}</P4>
          )}
        </TableCell>
      ),
      'UNI/BULTO': (
        <TableCell>
          <P4>{e.bultos_camada}</P4>
        </TableCell>
      ),
      'BULTO/PALLETS': (
        <TableCell>
          <P4>{e.bultos_piso * e.pisos_pallet}</P4>
        </TableCell>
      ),
    }),
    'PRECIO BASE': (
      <P4>
        <CurrencyFormat
          value={parseFloat(e.precio_base)}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={' $'}
          decimalScale={3}
          fixedDecimalScale={true}
        />
      </P4>
    ),
    'PRECIO VENTA': (
      <>
        <TableCell
          data-tooltip-id="Precio"
          data-tooltip-content={e.precio_real ? devolverPrecioReal(e) : ''}
          data-tooltip-place="top"
          $precioReal={e.precio_real}
        >
          <P4>
            <CurrencyFormat
              value={parseFloat(e.precio)}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={' $'}
              decimalScale={3}
              fixedDecimalScale={true}
            />
          </P4>
        </TableCell>
        <Tooltip id="Precio" delayShow={50} />
      </>
    ),

    'FECHA ACTUALIZACION': (
      <TableCell>
        <P4>{e.fecha_precio}</P4>
      </TableCell>
    ),
  }))

const ProductosProveedorTable = ({ puedePedir, nombreProveedor }) => {
  const { width } = useWindowWidth()
  const { data } = useSearchAndFilterTable()
  const navigate = useNavigate()
  const tableRef = useRef(null)

  const [articlesPerCategory, setArticlesPerCategory] = useState({})
  const [totalArticlesLoaded, setTotalArticlesLoaded] = useState(0)

  const LOAD_STEP = 20

  useEffect(() => {
    let initialArticles = {}
    let total = 0

    for (let category of data) {
      const toLoad = Math.min(category.articulos.length, LOAD_STEP - total)
      initialArticles[category.id] = toLoad
      total += toLoad

      if (total >= LOAD_STEP) break
    }

    setArticlesPerCategory(initialArticles)
    setTotalArticlesLoaded(total)
  }, [data])

  const loadMoreArticles = () => {
    let totalLoaded = totalArticlesLoaded
    const updatedArticlesPerCategory = { ...articlesPerCategory }

    for (let category of data) {
      const articlesCount = updatedArticlesPerCategory[category.id] || 0

      if (
        articlesCount < category.articulos.length &&
        totalLoaded < totalArticlesLoaded + LOAD_STEP
      ) {
        const remainingArticles = category.articulos.length - articlesCount
        const toLoad = Math.min(
          remainingArticles,
          LOAD_STEP - (totalLoaded - totalArticlesLoaded),
        )

        updatedArticlesPerCategory[category.id] = articlesCount + toLoad
        totalLoaded += toLoad
      }

      if (totalLoaded >= totalArticlesLoaded + LOAD_STEP) break
    }

    setArticlesPerCategory(updatedArticlesPerCategory)
    setTotalArticlesLoaded(totalLoaded)
  }

  const navigateToProductoPage = useCallback(async (idArticulo) => {
    navigate(`/comprar/${replaceText(nombreProveedor, ' ', '-')}/${idArticulo}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const headers = [
    ...(width > 440 ? [{ name: 'Estado', isOrderable: false }] : []),
    { name: 'Producto', isOrderable: false },
    { name: 'Uni / Medida', isOrderable: false },
    ...(width > 440
      ? [
          { name: 'EAN', isOrderable: false },
          { name: 'Uni / Bulto', isOrderable: false },
          { name: 'Bulto / Pallets', isOrderable: false },
        ]
      : []),
    { name: 'Precio base', isOrderable: false },
    { name: 'Precio zona', isOrderable: false },
    { name: 'Fecha de actualizacion', isOrderable: false },
  ]

  return (
    <>
      <CustomHeader
        numerate={false}
        headers={headers}
        gridTemplate={
          width > 440 ? '8% 18% 6% 12% 6% 6% 10% 10% 10%' : '30% 16% 20% 10%'
        }
      />
      {data?.length > 0 ? (
        <ScrolleableTable ref={tableRef}>
          {data.reduce(
            (sum, categoria) => sum + categoria.articulos.length,
            0,
          ) > 80 ? (
            <>
              {data
                .filter((categoria) => categoria.articulos.length > 0)
                .filter((categoria) =>
                  Object.keys(articlesPerCategory).includes(categoria.id),
                )
                .map((categoria, index) => {
                  return (
                    <CategoryContainer key={index}>
                      <SubTitleTable>{categoria.descripcion}</SubTitleTable>

                      <CustomTable
                        data={formatData(
                          {
                            categoria: categoria.descripcion,
                            articulos: categoria.articulos
                              .sort(
                                (a, b) =>
                                  isEstadoSuspendido(a.estado) -
                                  isEstadoSuspendido(b.estado),
                              )
                              .slice(0, articlesPerCategory[categoria.id] || 0),
                            puedePedir,
                            categoriaId: categoria.id,
                          },

                          navigateToProductoPage,
                          width,
                        )}
                        numerate={false}
                        gridTemplate={
                          width > 440
                            ? '8% 18% 6% 12% 6% 6% 10% 10% 10%'
                            : '32% 12% 18% 10%'
                        }
                      />
                    </CategoryContainer>
                  )
                })}

              {totalArticlesLoaded <
                data.reduce((acc, cat) => acc + cat.articulos.length, 0) && (
                <ButtonContainer>
                  <Button
                    onClick={loadMoreArticles}
                    text="Cargar más"
                    size="small"
                  />
                </ButtonContainer>
              )}
            </>
          ) : (
            data
              .filter((categoria) => categoria.articulos.length > 0)
              .map((categoria, index) => (
                <div key={index}>
                  <SubTitleTable>{categoria.descripcion}</SubTitleTable>
                  <CustomTable
                    data={formatData(
                      {
                        categoria: categoria.descripcion,
                        articulos: categoria.articulos.sort(
                          (a, b) =>
                            isEstadoSuspendido(a.estado) -
                            isEstadoSuspendido(b.estado),
                        ),
                        puedePedir,
                        categoriaId: categoria.id,
                      },

                      navigateToProductoPage,
                      width,
                    )}
                    numerate={false}
                    gridTemplate={
                      width > 440
                        ? '8% 18% 6% 12% 6% 6% 10% 10% 10%'
                        : '32% 12% 18% 10%'
                    }
                  />
                </div>
              ))
          )}
        </ScrolleableTable>
      ) : (
        <EmptyTableData>No se encontraron articulos</EmptyTableData>
      )}
    </>
  )
}

export default ProductosProveedorTable
