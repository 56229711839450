import styled from 'styled-components'
import CardCarousel from './components/CardCarousel'
import { Wrapper } from 'components/layout/FlexComponents'
import HorizontalPromoCarousel from 'components/Carousel/HorizontalPromoCarousel'
import { useProductos } from 'contexts/ProductosContext'
import { useAuth } from 'contexts/AuthContext'
import NotificationCenter from 'components/Notification/NotificationCenter'

const WrapperCards = styled(Wrapper)`
  margin: 60px 180px 0;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.neutral.white} 50%,
    #162240 50%
  );
`
const NotificationHome = styled(NotificationCenter)`
  padding: 0 10%;
  margin-top: 16px;
`

const formatArticulos = (categorias, user) => {
  // Usamos reduce para combinar todos los arrays de articulos
  return categorias.reduce((acumulador, categoria) => {
    if (categoria.articulos && Array.isArray(categoria.articulos)) {
      const articulosFormateados = categoria.articulos.map((articulo) => ({
        title: articulo.descripcion || '', // Si no existe, asigna un string vacío
        path_thumb: articulo.path_thumb || '', // Si no existe, asigna un string vacío
        precio: articulo.precio || 0, // Si no existe, asigna 0
        id: articulo.id_articulo,
        proveedor: user.nombre_comercial,
      }))
      // Añadimos los artículos formateados al acumulador
      return acumulador.concat(articulosFormateados)
    }
    return acumulador
  }, []) // Inicializamos el acumulador como un array vacío
}

const HomeProveedor = () => {
  const { productos } = useProductos()
  const { user } = useAuth()

  return (
    <Wrapper>
      <NotificationHome />
      <Wrapper>
        <WrapperCards>
          <CardCarousel />
        </WrapperCards>
        {productos?.articulos && Object.keys(productos).length > 0 && (
          <HorizontalPromoCarousel
            title="Mis Productos"
            type="primary"
            cards={formatArticulos(productos.articulos, user)}
            cronogramaHabilitado={true}
          />
        )}
      </Wrapper>
    </Wrapper>
  )
}

export default HomeProveedor
