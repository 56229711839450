import React from 'react'
import styled from 'styled-components'
import Tag from './Tag'

const getColorByEstado = (estado, theme) => {
  switch (estado) {
    case 'PROMO':
      return theme.colors.primary.darkBlue
    case 'Generado':
    case 'Aprobado':
    case 'En Preparación':
    case 'Facturado':
    case 'Consolidado':
    case 'Despachado':
      return theme.colors.alarmsStatuses.green
    case 'Cancelado':
    case 'Baja':
    case 'Desaprobado':
    case 'No Confirmado':
      return theme.colors.alarmsStatuses.red
    case 'No Consolidado':
    case 'Para Liquidar':
    case 'Liquidado Parcial':
    case 'No consolidado':
    case 'En curso':
      return theme.colors.alarmsStatuses.yellow
    case 'Deshabilitado':
    case 'Sin Movimientos':
    case 'Borrador':
    case 'Suspendido':
      return theme.colors.neutral.gray
    case 'Confirmado en 0':
    case 'Confirmado':
    case 'Activo':
    case 'En Curso':
    case 'Habilitado':
    default:
      return theme.colors.primary.lightBlue
  }
}

const StyledTag = styled(Tag)`
  display: inline-block;
  cursor: default;
  padding: 6px 8px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.7px;
  background-color: ${({ $estado, theme }) => getColorByEstado($estado, theme)};
  color: ${({ theme }) => theme.colors.neutral.white};

  @media (max-width: 1366px) {
    font-size: 11px;
    padding: 6px;
  }
`

const StatusTag = ({ estado }) => {
  const estadoTraslate =
    estado === 'Confeccionado'
      ? 'Confirmado'
      : // : estado === 'Generado'
        //   ? 'Consolidado'
        estado
  return <StyledTag $estado={estadoTraslate}>{estadoTraslate}</StyledTag>
}

export default StatusTag
