import { createContext, useContext, useState } from 'react'
import PedidosService from 'services/pedidos'
import { useAuth } from './AuthContext'
import { useCronograma } from './CronogramaContext'
import CarritoService from 'services/carrito'
import { useCarrito } from './CarritoContext'
import { getLocalStorageItem } from 'utils/loadData'
import axios from 'axios'

const traduccionOrden = {
  proveedor: 'proveedor',
  retiro: 'entrega',
  fecha: 'fecha_confeccion',
  oc: 'ordenDeCompra',
  '-proveedor': '-proveedor',
  '-retiro': '-entrega',
  '-fecha': '-fecha_confeccion',
  '-oc': '-ordenDeCompra',
  '-confeccion': '-fecha_confeccion',
  confeccion: 'fecha_confeccion',
  entrega: 'entrega',
  '-entrega': '-entrega',
}

const PedidosContext = createContext()

export const usePedidos = () => useContext(PedidosContext)

export const PedidosProvider = ({ children }) => {
  const { user, token } = useAuth()
  const { cronograma } = useCronograma()
  const { carritoAgrupado, getCarritoAgrupado } = useCarrito()
  const [participacion, setParticipacion] = useState(() =>
    getLocalStorageItem('participacion', {}),
  )
  const [matrizConsolidacion, setMatrizConsolidacion] = useState(() =>
    getLocalStorageItem('matrizConsolidacion', {}),
  )
  const [oc, setOc] = useState(() => getLocalStorageItem('oc'))
  const [ocAgrupacion, setOcAgrupacion] = useState(() =>
    getLocalStorageItem('ocAgrupacion', {}),
  )
  const [misPedidos, setMisPedidos] = useState(() =>
    getLocalStorageItem('misPedidos', {}),
  )
  const [pedidosAgrupacion, setPedidosAgrupacion] = useState(() =>
    getLocalStorageItem('pedidosAgrupacion', {}),
  )

  const [pedidosProveedor, setPedidosProveedor] = useState(() =>
    getLocalStorageItem('pedidosProveedor', {}),
  )
  const [recepcionPedido, setRecepcionPedido] = useState(() =>
    getLocalStorageItem('recepcionPedido', {}),
  )
  const [pedidosAConsolidar, setPedidosAConsolidar] = useState(() =>
    getLocalStorageItem('pedidosAConsolidar', {}),
  )
  const [comprobantes, setComprobantes] = useState(() =>
    getLocalStorageItem('comprobantes', {}),
  )

  const [pedidoConfeccionado, setPedidoConfeccionado] = useState(() =>
    getLocalStorageItem('pedidoConfeccionado', {}),
  )

  const [pedidosLoading, setPedidosLoading] = useState(false)
  const [participacionLoading, setParticipacionLoading] = useState(false)
  const [pedidoLoading, setPedidoLoading] = useState(false)
  const [pedidoConsolidado, setPedidoConsolidado] = useState(false)
  const [editPedidosLoading, setEditPedidosLoading] = useState(false)
  const [conoslidacionLoading, setConoslidacionLoading] = useState(false)

  const formatoFecha = (fecha) => {
    const año = fecha.getFullYear()
    const mes = String(fecha.getMonth() + 1).padStart(2, '0')
    const dia = String(fecha.getDate()).padStart(2, '0')
    return `${año}-${mes}-${dia}`
  }
  function obtenerFechas({ este_mes = false, mes_pasado = false } = {}) {
    const hoy = new Date()
    let fecha_desde, fecha_hasta

    if (este_mes) {
      fecha_desde = new Date(hoy.getFullYear(), hoy.getMonth(), 1)
      fecha_hasta = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0)
    } else if (mes_pasado) {
      const primerDiaMesPasado = new Date(
        hoy.getFullYear(),
        hoy.getMonth() - 1,
        1,
      )
      const ultimoDiaMesPasado = new Date(hoy.getFullYear(), hoy.getMonth(), 0) // Día 0 del mes actual es el último día del mes pasado
      fecha_desde = primerDiaMesPasado
      fecha_hasta = ultimoDiaMesPasado
    }

    return {
      fecha_desde: formatoFecha(fecha_desde),
      fecha_hasta: formatoFecha(fecha_hasta),
    }
  }

  const getParticipacion = async (idProveedor) => {
    setParticipacionLoading(true)
    return await PedidosService.getParticipacion({
      idCronograma: cronograma.id_cronograma,
      idProveedor,
      idGrupo: user.grupo.id_grupo,
      token,
    })
      .then((resp) => {
        localStorage.setItem('participacion', JSON.stringify(resp.data.data))
        setParticipacion(resp.data.data)
        return resp.data.data
      })
      .catch((error) => {
        console.error('Error obteniendo participación: ', error)
        throw error
      })
      .finally(() => setParticipacionLoading(false))
  }

  const getMatrizConsolidacion = async (idProveedor, idZona) => {
    setPedidosLoading(true)
    await PedidosService.getMatrizConsolidacion({
      idCronograma: cronograma.id_cronograma,
      idProveedor,
      idZona,
      token,
    })
      .then((resp) => {
        localStorage.setItem(
          'matrizConsolidacion',
          JSON.stringify(resp.data.data),
        )
        setMatrizConsolidacion(resp.data.data)
      })
      .catch((error) => {
        console.error('Error obteniendo datos de matriz: ', error)
        throw error
      })
      .finally(() => setPedidosLoading(false))
  }

  const getOc = async (idOcCabecera) => {
    setPedidosLoading(true)
    await PedidosService.getOc({ idOcCabecera: parseInt(idOcCabecera), token })
      .then((resp) => {
        localStorage.setItem('oc', JSON.stringify(resp.data.data))
        setOc(resp.data.data)
      })
      .catch((error) => {
        console.error('Error obteniendo orden de compra: ', error)
        throw error
      })
      .finally(() => setPedidosLoading(false))
  }

  const getOcAgrupacion = async (idOcCabecera) => {
    setPedidosLoading(true)
    await PedidosService.getOcAgrupacion({
      idOcCabecera,
      token,
    })
      .then((resp) => {
        localStorage.setItem('ocAgrupacion', JSON.stringify(resp.data.data))
        setOcAgrupacion(resp.data.data)
      })
      .catch((error) => {
        console.error('Error obteniendo orden de compra: ', error)
        throw error
      })
      .finally(() => setPedidosLoading(false))
  }

  const getPedidosProveedor = async (props) => {
    const {
      todos,
      currentPage,
      activeOrder,
      este_mes,
      mes_pasado,
      historico,
      operación_directa,
      marcas_líderes,
      cancelToken,
      todos_estados,
      aprobado,
      facturado,
      en_preparacion,
      generado,
      despachado,
      // recepcion_parcial,
      // recepcionado,
      desaprobado,
      cancelado,
      confeccion,
      periodo,
      searchValue,
    } = props
    setPedidosLoading(true)

    const tipo_proveedor = marcas_líderes
      ? 1
      : operación_directa || despachado
        ? 0
        : null

    const fechas =
      periodo.fecha_inicio !== '' || periodo.fecha_fin !== ''
        ? {
            fecha_desde:
              periodo.fecha_inicio !== '' ? periodo.fecha_inicio : null,
            fecha_hasta: periodo.fecha_fin !== '' ? periodo.fecha_fin : null,
          }
        : !historico || historico === undefined
          ? (este_mes || mes_pasado) && obtenerFechas({ este_mes, mes_pasado })
          : undefined

    const estado = todos_estados
      ? null
      : aprobado
        ? 6
        : facturado || despachado
          ? 9
          : en_preparacion
            ? 20
            : generado
              ? 17
              : desaprobado
                ? 7
                : cancelado
                  ? 10
                  : confeccion
                    ? 5
                    : 0

    // const recepcion = recepcion_parcial ? 4 : recepcionado ? 1 : 0

    await PedidosService.getPedidosProveedor({
      idCronograma: todos && !confeccion ? undefined : cronograma.id_cronograma,
      paginaActual: currentPage,
      porPagina: 10,
      activeOrder: traduccionOrden[activeOrder],
      token,
      tipo_proveedor,
      fechas: fechas ? fechas : undefined,
      estado,
      searchValue,
      // recepcion,
      cancelToken,
    })
      .then((resp) => {
        localStorage.setItem('pedidosProveedor', JSON.stringify(resp.data.data))
        setPedidosProveedor(resp.data.data)
        setPedidosLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Solicitud anterior cancelada: ', error.message)
        } else {
          setPedidosProveedor({})
          setPedidosLoading(false)
          console.error('Error obteniendo pedidos: ', error)
        }
      })
  }

  const getMisPedidos = async (props) => {
    const {
      todos,
      currentPage,
      activeOrder,
      este_mes,
      mes_pasado,
      historico,
      operación_directa,
      marcas_líderes,
      cancelToken,
      todos_estados,
      aprobado,
      facturado,
      despachado,
      en_preparacion,
      generado,
      no_consolidado,
      confeccion,
      desaprobado,
      cancelado,
      periodo,
      searchValue,
    } = props
    setPedidosLoading(true)

    const estado = todos_estados
      ? null
      : aprobado
        ? 6
        : facturado || despachado
          ? 9
          : en_preparacion
            ? 20
            : generado
              ? 17
              : desaprobado
                ? 7
                : cancelado
                  ? 10
                  : confeccion
                    ? 5
                    : no_consolidado
                      ? 18
                      : 0

    const tipo_proveedor = marcas_líderes
      ? 1
      : operación_directa || despachado
        ? 0
        : null

    const fechas =
      periodo && (periodo?.fecha_inicio !== '' || periodo?.fecha_fin !== '')
        ? {
            fecha_desde:
              periodo.fecha_inicio !== '' ? periodo.fecha_inicio : null,
            fecha_hasta: periodo.fecha_fin !== '' ? periodo.fecha_fin : null,
          }
        : !historico || historico === undefined
          ? (este_mes || mes_pasado) && obtenerFechas({ este_mes, mes_pasado })
          : undefined

    await PedidosService.getMisPedidos({
      idCronograma: todos && !confeccion ? undefined : cronograma.id_cronograma,
      paginaActual: currentPage,
      porPagina: 10,
      activeOrder: traduccionOrden[activeOrder],
      token,
      fechas: fechas ? fechas : undefined,
      tipo_proveedor,
      estado,
      searchValue,
      // recepcion,
      cancelToken,
    })
      .then((resp) => {
        localStorage.setItem('misPedidos', JSON.stringify(resp.data.data))
        setMisPedidos(resp.data.data)
        setPedidosLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Solicitud anterior cancelada: ', error.message)
        } else {
          setMisPedidos({})
          setPedidosLoading(false)
          console.error('Error obteniendo pedidos: ', error)
        }
      })
  }

  const getPedidosAgrupacion = async (props) => {
    const {
      todos,
      currentPage,
      activeOrder,
      este_mes,
      mes_pasado,
      historico,
      operación_directa,
      marcas_líderes,
      cancelToken,
      todos_estados,
      aprobado,
      facturado,
      en_preparacion,
      generado,
      despachado,
      // recepcion_parcial,
      // recepcionado,
      desaprobado,
      cancelado,
      confeccion,
      periodo,
      searchValue,
    } = props
    setPedidosLoading(true)

    const tipo_proveedor = marcas_líderes
      ? 1
      : operación_directa || despachado
        ? 0
        : null

    const fechas =
      periodo.fecha_inicio !== '' || periodo.fecha_fin !== ''
        ? {
            fecha_desde:
              periodo.fecha_inicio !== '' ? periodo.fecha_inicio : null,
            fecha_hasta: periodo.fecha_fin !== '' ? periodo.fecha_fin : null,
          }
        : !historico || historico === undefined
          ? (este_mes || mes_pasado) && obtenerFechas({ este_mes, mes_pasado })
          : undefined

    const estado = todos_estados
      ? null
      : aprobado
        ? 6
        : facturado || despachado
          ? 9
          : en_preparacion
            ? 20
            : generado
              ? 17
              : desaprobado
                ? 7
                : cancelado
                  ? 10
                  : confeccion
                    ? 5
                    : 0

    // const recepcion = recepcion_parcial ? 4 : recepcionado ? 1 : 0

    await PedidosService.getPedidosAgrupacion({
      idCronograma: todos && !confeccion ? undefined : cronograma.id_cronograma,
      paginaActual: currentPage,
      porPagina: 10,
      activeOrder: traduccionOrden[activeOrder],
      token,
      tipo_proveedor,
      fechas: fechas ? fechas : undefined,
      estado,
      searchValue,
      // recepcion,
      cancelToken,
    })
      .then((resp) => {
        localStorage.setItem(
          'pedidosAgrupacion',
          JSON.stringify(resp.data.data),
        )
        setPedidosAgrupacion(resp.data.data)
        setPedidosLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Solicitud anterior cancelada: ', error.message)
        } else {
          setPedidosAgrupacion({})
          setPedidosLoading(false)
          console.error('Error obteniendo pedidos: ', error)
        }
      })
  }

  const getPedidoConfeccionado = async (idCarrito, idProveedor) => {
    setPedidosLoading(true)
    await PedidosService.getPedidoConfeccionado({
      idCarrito,
      idProveedor,
      token,
    })
      .then((resp) => {
        localStorage.setItem(
          'pedidoConfeccionado',
          JSON.stringify(resp.data.data),
        )
        setPedidoConfeccionado(resp.data.data)
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
      .finally(() => setPedidosLoading(false))
  }
  const getPedidoAgrupacion = async (idOcCabecera, ordenDeCompra) => {
    setPedidoLoading(true)
    return await PedidosService.getPedidoAgrupacion({
      idOcCabecera,
      token,
    })
      .then(async (resp) => {
        if (resp.data.data.pasoRecepcion === 1) {
          localStorage.setItem(
            'recepcionPedido',
            JSON.stringify({
              dataPaso: resp.data.data,
              paso: resp.data.data.pasoRecepcion,
              ordenDeCompra,
            }),
          )
          setRecepcionPedido({
            dataPaso: resp.data.data,
            paso: resp.data.data.pasoRecepcion,
            ordenDeCompra,
          })
          return resp.data.data.pasoRecepcion
        } else {
          localStorage.setItem(
            'recepcionPedido',
            JSON.stringify({
              dataPaso: {
                ...resp.data.data,
                id_cabecera_recepcion: idOcCabecera,
              },
              paso: resp.data.data.pasoRecepcion,
              ordenDeCompra: recepcionPedido.ordenDeCompra,
            }),
          )
          setRecepcionPedido({
            dataPaso: {
              ...resp.data.data,
              id_cabecera_recepcion: idOcCabecera,
            },
            paso: resp.data.data.pasoRecepcion,
            ordenDeCompra: recepcionPedido.ordenDeCompra,
          })
          return resp.data.data.pasoRecepcion
        }
      })
      .catch((error) => {
        console.error('Error obteniendo pedidos: ', error)
        throw error
      })
      .finally(() => {
        setPedidoLoading(false)
      })
  }

  const getPedidosAConsolidar = async () => {
    setPedidosLoading(true)
    await PedidosService.getPedidosAConsolidar({
      idCronograma: cronograma.id_cronograma,
      idGrupo: user.grupo.id_grupo,
      token,
    })
      .then((resp) => {
        localStorage.setItem(
          'pedidosAConsolidar',
          JSON.stringify(resp.data.data),
        )
        setPedidosAConsolidar(resp.data.data)
      })
      .catch((error) => {
        console.error('Error obteniendo datos del proveedor: ', error)
        throw error
      })
      .finally(() => setPedidosLoading(false))
  }

  const consolidarPedidos = async (
    idCronograma,
    idProveedor,
    oc_grupo,
    fecha_vto,
    id_zona,
    id_lugar,
    observacion,
  ) => {
    setPedidosLoading(true)
    await PedidosService.consolidarPedido({
      idCronograma,
      idProveedor,
      idGrupo: user.grupo.id_grupo,
      oc_grupo,
      fecha_vto,
      id_zona,
      id_lugar,
      observacion,
      token,
    })
      .then((resp) => {
        // revisar la acción dependiendo de lo que retorne el endpoint, probablemente modificar los pedidosAConsolidar en localstorage sin el pedido consolidado o hacer un getProveedoresAConsolidar.
        console.log('consolidarPedidos response: ', resp)
        setPedidoConsolidado(true)
      })
      .catch((error) => {
        console.error('Error consolidando pedido: ', error)
        setPedidoConsolidado(false)
      })
      .finally(() => setPedidosLoading(false))
  }

  const consolidarPedidosMatriz = async (
    idCronograma,
    idProveedor,
    oc_grupo,
    fecha_vto,
    id_zona,
    id_lugar,
    observacion,
    afiliados,
  ) => {
    setConoslidacionLoading(true)
    await PedidosService.consolidarPedidoMatriz({
      idCronograma,
      idProveedor,
      idGrupo: user.grupo.id_grupo,
      oc_grupo,
      fecha_vto,
      id_zona,
      id_lugar,
      observacion,
      afiliados,
      token,
    })
      .then((resp) => {
        // revisar la acción dependiendo de lo que retorne el endpoint, probablemente modificar los pedidosAConsolidar en localstorage sin el pedido consolidado o hacer un getProveedoresAConsolidar.
        console.log('consolidarPedidos response: ', resp)
      })
      .catch((error) => {
        console.error('Error consolidando pedidos: ', error)
        throw error
      })
      .finally(() => setConoslidacionLoading(false))
  }

  const recibirCantidadesPedido = async (idOcCabecera, cantidades) => {
    setPedidoLoading(true)

    try {
      const res = await PedidosService.recibirCantidadesPedido({
        idOcCabecera,
        cantidades,
        token,
      })

      recepcionPedido.dataPaso.pedido.detalle = res.pedidoDetalle
      recepcionPedido.dataPaso.ocRecepcion = res.ocRecepcion
      recepcionPedido.paso = 2
      localStorage.setItem('recepcionPedido', JSON.stringify(recepcionPedido))
      setRecepcionPedido(recepcionPedido)
    } catch (error) {
      console.error('Error recibiendo pedidos: ', error)
      throw error
    } finally {
      setPedidoLoading(false)
    }
  }

  const distribuirPedido = async (
    idOcCabecera,
    idCabeceraRecepcion,
    cantidades,
  ) => {
    setPedidoLoading(true)
    try {
      const res = await PedidosService.distribuirPedido({
        idOcCabecera,
        idCabeceraRecepcion,
        cantidades,
        token,
      })

      localStorage.setItem(
        'recepcionPedido',
        JSON.stringify({
          dataPaso: { ...res, ocRecepcion: res.ocRecepcion },
          paso: 3,
          ordenDeCompra: recepcionPedido.ordenDeCompra,
        }),
      )
      setRecepcionPedido({
        dataPaso: res,
        paso: 3,
        ordenDeCompra: recepcionPedido.ordenDeCompra,
      })
    } catch (error) {
      console.error('Error distribuyendo pedidos: ', error)
      throw error
    } finally {
      setPedidoLoading(false)
    }
  }

  const generarRemitos = async (idOcCabecera, idCabeceraRecepcion, remitos) => {
    setPedidosLoading(true)
    try {
      const res = await PedidosService.generarRemitos({
        idOcCabecera,
        idCabeceraRecepcion,
        remitos,
        token,
      })

      localStorage.setItem(
        'recepcionPedido',
        JSON.stringify({
          dataPaso: res,
          paso: 4,
          ordenDeCompra: recepcionPedido.ordenDeCompra,
        }),
      )
      setRecepcionPedido({
        dataPaso: res,
        paso: 4,
        ordenDeCompra: recepcionPedido.ordenDeCompra,
      })
    } catch (error) {
      console.error('Error generando remitos: ', error)
      throw error
    } finally {
      setPedidosLoading(false)
    }
  }

  const getComprobantes = async (idOcCabecera, idAfiliado = null) => {
    setPedidosLoading(true)
    await PedidosService.getComprobantes({
      idOcCabecera,
      idAfiliado,
      token,
    })
      .then((resp) => {
        localStorage.setItem('comprobantes', JSON.stringify(resp.data.data))
        setComprobantes(resp.data.data)
      })
      .catch((error) => {
        console.error('Error obteniendo comprobantes: ', error)
      })
      .finally(() => setPedidosLoading(false))
  }

  const obtenerPdfPedidoSubasociada = async (idOcCabecera, idAfiliado) => {
    setPedidosLoading(true)
    try {
      const res = await PedidosService.obtenerPdfPedidoSubasociada({
        idOcCabecera,
        idSubasociada: idAfiliado ? idAfiliado : user.id,
        token,
      })
      setPedidosLoading(false)
      return res
    } catch (error) {
      console.error('Error obteniendo PDF: ', error)
      setPedidosLoading(false)
      throw error
    }
  }

  const obtenerPdfPedidoAgrupacion = async (idOcCabecera) => {
    setPedidosLoading(true)
    try {
      const res = await PedidosService.obtenerPdfPedidoAgrupacion({
        idOcCabecera,
        token,
      })
      setPedidosLoading(false)
      return res
    } catch (error) {
      console.error('Error obteniendo PDF: ', error)
      setPedidosLoading(false)
      throw error
    }
  }

  const obtenerPdfRemito = async (
    idOcCabecera,
    idCabeceraRecepcion,
    idAfiliado = null,
  ) => {
    setPedidosLoading(true)
    try {
      const res = await PedidosService.obtenerPdfRemito({
        idOcCabecera,
        idCabeceraRecepcion,
        idAfiliado,
        token,
      })
      setPedidosLoading(false)
      return res
    } catch (error) {
      console.error('Error obteniendo PDF: ', error)
      setPedidosLoading(false)
      throw error
    }
  }

  const obtenerPdfFactura = async (
    idFactura,
    idOcCabecera,
    idCabeceraRecepcion,
    idAfiliado = null,
  ) => {
    setPedidosLoading(true)
    try {
      const res = await PedidosService.obtenerPdfFactura({
        idFactura,
        idOcCabecera,
        idCabeceraRecepcion,
        idAfiliado,
        token,
      })
      setPedidosLoading(false)
      return res
    } catch (error) {
      console.error('Error obteniendo PDF: ', error)
      throw error
    } finally {
      setPedidosLoading(false)
    }
  }

  const cancelarConfirmacionCarrito = async (idCronograma, idProveedor) => {
    setEditPedidosLoading(true)
    try {
      const res = await PedidosService.cancelarConfirmacionCarrito({
        idCronograma,
        idProveedor,
        idCarrito: carritoAgrupado.cabecera.id,
        token,
      })
      await getCarritoAgrupado()
      return res
    } catch (error) {
      console.error('Error confirmando carrito: ', error)
      throw error
    } finally {
      setEditPedidosLoading(false)
    }
  }

  const removerMisPedidosCarrito = async (idProveedor, idCarrito) => {
    setEditPedidosLoading(true)
    if (token) {
      try {
        const resp = await CarritoService.remover({
          idCarrito,
          idProveedor,
          pedido: true,
          token,
        })
        return resp
      } catch (error) {
        console.error('Error removiendo proveedor del carrito: ', error)
        throw error
      } finally {
        setEditPedidosLoading(false)
      }
    }
  }

  const removerPedidoGenerado = async (idOcCabecera) => {
    setEditPedidosLoading(true)
    if (token) {
      try {
        const resp = await PedidosService.removerPedidoGenerado({
          idOcCabecera,
          token,
        })
        return resp
      } catch (error) {
        console.error('Error removiendo pedido', error)
        throw error
      } finally {
        setEditPedidosLoading(false)
      }
    }
  }

  const contextValues = {
    pedidosAConsolidar,
    setPedidosAgrupacion,
    getPedidosAgrupacion,
    pedidosAgrupacion,
    getPedidoAgrupacion,
    setPedidosAConsolidar,
    getPedidosAConsolidar,
    consolidarPedidos,
    consolidarPedidosMatriz,
    recibirCantidadesPedido,
    cancelarConfirmacionCarrito,
    removerMisPedidosCarrito,
    distribuirPedido,
    pedidosLoading,
    pedidoConsolidado,
    getMisPedidos,
    setMisPedidos,
    misPedidos,
    getPedidoConfeccionado,
    pedidoConfeccionado,
    getOcAgrupacion,
    setOcAgrupacion,
    ocAgrupacion,
    getOc,
    setOc,
    oc,
    getParticipacion,
    setParticipacion,
    participacionLoading,
    participacion,
    getMatrizConsolidacion,
    setMatrizConsolidacion,
    matrizConsolidacion,
    generarRemitos,
    pedidoLoading,
    recepcionPedido,
    setRecepcionPedido,
    obtenerPdfPedidoSubasociada,
    obtenerPdfPedidoAgrupacion,
    obtenerPdfRemito,
    obtenerPdfFactura,
    editPedidosLoading,
    setEditPedidosLoading,
    comprobantes,
    setComprobantes,
    getComprobantes,
    conoslidacionLoading,
    setConoslidacionLoading,
    removerPedidoGenerado,
    getPedidosProveedor,
    pedidosProveedor,
  }

  return (
    <PedidosContext.Provider value={contextValues}>
      {children}
    </PedidosContext.Provider>
  )
}
