import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table/Table'
import HeaderTable from 'components/Table/HeaderTable'
import { BtnM, P2, P4 } from 'components/Typography'
import CurrencyFormat from 'react-currency-format'
import { Tooltip } from 'react-tooltip'

const TableWrapper = styled.div`
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const ScrolleableTable = styled.div`
  max-height: 450px;
  overflow: scroll;
`

const TotalesTableSection = styled.div`
  display: grid;
  grid-template-columns: 3fr 0.8fr 0.8fr 1fr 1fr;
  gap: 15px;
  padding: 24px 30px 6px 45px;
  & > :nth-child(1) {
    grid-column-start: 2;
  }

  @media (max-width: 1366px) {
    padding: 24px 30px 6px 45px;
  }
`

const TableCell = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ $precioReal }) => ($precioReal ? 'pointer' : 'initial')};
`

const devolverPrecioReal = (articulo) => {
  const precioReal = parseFloat(articulo.precio_real).toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 3,
  })

  return precioReal
}

const formatData = ({ articulos }) =>
  articulos.map((e) => ({
    EAN: <P2>{e.ean13}</P2>,
    Producto: <P2>{e.descripcion}</P2>,
    'Precio unitario': (
      <>
        <TableCell
          data-tooltip-id="Precio"
          data-tooltip-content={e.precio_real ? devolverPrecioReal(e) : ''}
          data-tooltip-place="top"
          $precioReal={e.precio_real}
        >
          <P4>
            <CurrencyFormat
              value={parseFloat(e.precio_pedido)}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'$'}
              decimalScale={3}
              fixedDecimalScale={true}
            />
          </P4>
        </TableCell>
        <Tooltip id="Precio" delayShow={50} />
      </>
    ),
    'Bultos pedidos': <P2>{e.cant_pedido}</P2>,
    Neto: (
      <CurrencyFormat
        value={parseFloat(e.neto_pedido)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    'subtotal c/iva': (
      <CurrencyFormat
        value={parseFloat(e.total_pedido)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
  }))

const headers = [
  { name: 'EAN', isOrderable: false },
  { name: 'Producto', isOrderable: false },
  { name: 'Precio unitario', isOrderable: false },
  { name: 'Bultos pedidos', isOrderable: false },
  { name: 'Neto', isOrderable: false },
  { name: 'subtotal c/iva', isOrderable: false },
]

const PedidosAgrupadosTable = ({ pedidos, totales }) => {
  return (
    <TableWrapper>
      <HeaderTable
        numerate={false}
        headers={headers}
        gridTemplate="1fr, 2fr, 0.8fr, 0.8fr, 1fr, 1fr"
      />
      {pedidos?.length > 0 ? (
        <ScrolleableTable>
          <Table
            data={formatData({
              articulos: pedidos,
            })}
            numerate={false}
            gridTemplate="1fr, 2fr, 0.8fr, 0.8fr, 1fr, 1fr"
          />
        </ScrolleableTable>
      ) : (
        <EmptyTableData>No se encontraron articulos</EmptyTableData>
      )}
      <TotalesTableSection>
        <BtnM>Totales:</BtnM>
        <BtnM>{totales?.bultos_pedido || totales?.bultos}</BtnM>
        <BtnM>
          <CurrencyFormat
            value={parseFloat(totales?.subtotal || totales?.neto_pedido)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'$'}
            decimalScale={3}
            fixedDecimalScale={true}
          />
        </BtnM>
        <BtnM>
          <CurrencyFormat
            value={parseFloat(totales?.total || totales?.total_pedido)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'$'}
            decimalScale={3}
            fixedDecimalScale={true}
          />
        </BtnM>
      </TotalesTableSection>
    </TableWrapper>
  )
}

export default PedidosAgrupadosTable
