import React from 'react'
import styled from 'styled-components'

import BackButton from './BackButton'
import Breadcrumbs from './Breadcrumbs'
import CronogramaDateText from 'components/CronogramaDate/CronogramaDateText'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'

const StyledRoutesComponent = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  box-sizing: border-box;
  bottom: -28px;
  left: 0;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.primary.blue};
  color: ${({ theme }) => theme.colors.neutral.white};
  width: 100%;
`

const RoutesWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const RoutesComponent = ({ dontHandleRoutes }) => {
  const { user } = useAuth()
  const location = useLocation()
  const routesComponentView = location.pathname !== '/'

  return (
    <StyledRoutesComponent>
      <RoutesWrapper>
        {routesComponentView && (
          <>
            {user.rol.id_rol == !'5' && <BackButton />}
            {!dontHandleRoutes && <Breadcrumbs />}
          </>
        )}
      </RoutesWrapper>
      <CronogramaDateText />
    </StyledRoutesComponent>
  )
}

export default RoutesComponent
