import { P1 } from 'components/Typography'
import styled from 'styled-components'

const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const DateInputContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

const DateLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #1e3a8a;
`

const DateInput = styled.input`
  margin-top: 4px;
  border: 2px solid #1e3a8a;
  border-radius: 8px;
  padding: 6px;
  font-size: 12px;
  color: #1e3a8a;
  text-align: center;
  cursor: pointer;
  width: 80%;
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
const Input = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin: 0;
  margin-right: 5px;
  position: relative;
  vertical-align: text-top;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary.darkBlue};

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary.darkBlue};
  }

  &::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff" width="16px" height="16px"><path d="M0 0h24v24H0V0z" fill="none"/><path fill="%23ffffff" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>');
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    opacity: 0;
  }

  &:checked::before {
    opacity: 1;
  }
`
const Label = styled(P1)`
  margin-top: 8px;
`

const DateRangePicker = ({ dateRange, setDateRange }) => {
  const handleStartDateChange = (e) => {
    const fecha = new Date(e.target.value + 'T00:00:00')
    fecha.setMinutes(fecha.getMinutes() - fecha.getTimezoneOffset()) // Ajuste de zona horaria
    const adjustedDate = fecha.toISOString().split('T')[0] // Extraemos la fecha ajustada
    setDateRange({
      ...dateRange,
      fecha_inicio: adjustedDate,
    })
  }

  const handleEndDateChange = (e) => {
    const fecha = new Date(e.target.value + 'T23:59:59')
    fecha.setMinutes(fecha.getMinutes() - fecha.getTimezoneOffset()) // Ajuste de zona horaria
    const adjustedDate = fecha.toISOString().split('T')[0] // Extraemos la fecha ajustada
    setDateRange({
      ...dateRange,
      fecha_fin: adjustedDate,
    })
  }

  return (
    <DateRangeContainer>
      <Label>
        <Input
          type="checkbox"
          checked={dateRange.fecha_fin !== '' || dateRange.fecha_inicio !== ''}
          disabled={false}
        />
        Período
      </Label>
      <DateInputContainer>
        <DateLabel>
          Desde
          <DateInput
            type="date"
            value={dateRange.fecha_inicio}
            max={dateRange.fecha_fin || undefined}
            onChange={handleStartDateChange}
          />
        </DateLabel>
        <DateLabel>
          Hasta
          <DateInput
            type="date"
            value={dateRange.fecha_fin}
            min={dateRange.fecha_inicio || undefined}
            onChange={handleEndDateChange}
          />
        </DateLabel>
      </DateInputContainer>
    </DateRangeContainer>
  )
}

export default DateRangePicker
