import styled from 'styled-components'
import { H3, P1 } from 'components/Typography'
import FilterTag from './FilterTag'
import {
  capitalizeFirstLetter,
  reemplazarInterrogacionPorÑ,
} from 'utils/textProcess'
import DateRangePicker from 'components/Input/InputRangoFecha'

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const FilterContainer = styled.div`
  padding: 18px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin: 0;
  margin-right: 5px;
  position: relative;
  vertical-align: text-top;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary.darkBlue};

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary.darkBlue};
  }

  &::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff" width="16px" height="16px"><path d="M0 0h24v24H0V0z" fill="none"/><path fill="%23ffffff" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>');
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    opacity: 0;
  }

  &:checked::before {
    opacity: 1;
  }
`

const Label = styled(P1)`
  margin-top: 8px;
`

const FilterGroup = styled.div`
  padding: 18px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-bottom: 18px;
`

const SelectedFilters = styled.div`
  margin: 16px 0;
`

// const initializeFilters = (filtersOptions, filters) => {
//   if (Object.keys(filters).length > 0) {
//     return filters
//   } else {
//     const initialFilters = {}
//     Object.keys(filtersOptions).forEach((group) => {
//       filtersOptions[group].forEach(({ key, default: isDefault }) => {
//         initialFilters[key] = isDefault || false
//       })
//     })
//     return initialFilters
//   }
// }

export const FilterComponent = ({
  filtersOptions,
  setFilters,
  filters,
  onlyOneOption = false,
}) => {
  const findTagByKey = (key) =>
    Object.values(filtersOptions)
      .flat()
      .find((item) => item.key === key)?.tag || null

  const handleSelectFilter = (key, value = {}) => {
    const updatedFilters = { ...filters }
    const filterGroup = Object.keys(filtersOptions).find((group) =>
      filtersOptions[group].some((option) => option.key === key),
    )

    // Verificar si el estado anterior era 'facturado' o 'despachado'
    if (filters['facturado'] || filters['despachado']) {
      updatedFilters['operación_directa'] = false
      updatedFilters['marcas_líderes'] = false
      updatedFilters['todos_provedores'] = true
    }

    if (filterGroup === 'Estados' && key === 'despachado') {
      updatedFilters['operación_directa'] = true
      updatedFilters['marcas_líderes'] = false
      updatedFilters['todos_provedores'] = false
    }
    if (filterGroup === 'Estados' && key === 'facturado') {
      updatedFilters['marcas_líderes'] = true
      updatedFilters['operación_directa'] = false
      updatedFilters['todos_provedores'] = false
    }

    if (key === 'todos' || onlyOneOption) {
      filtersOptions[filterGroup].forEach(({ key: optionKey }) => {
        updatedFilters[optionKey] = false
      })
      updatedFilters[key] = true
    } else {
      updatedFilters[key] = !updatedFilters[key]
      if (filterGroup === 'Productos') {
        updatedFilters['todos'] = false

        if (Object.values(updatedFilters).every((value) => !value)) {
          updatedFilters['todos'] = true
        }
      }
    }
    if (filterGroup === 'Fecha') {
      if (key === 'este_mes' || key === 'mes_pasado' || key === 'historico') {
        updatedFilters['periodo'] = {
          fecha_inicio: '',
          fecha_fin: '',
        }
      } else {
        updatedFilters['este_mes'] = false
        updatedFilters['mes_pasado'] = false
        updatedFilters['historico'] = false
        updatedFilters['periodo'] = value
      }
    }

    setFilters(updatedFilters)
  }

  const handleRemoveFilter = (group, key) => {
    const updatedFilters = { ...filters }
    updatedFilters[key] = false
    const groupFilters = filtersOptions[group]
    if (!groupFilters.some((filter) => updatedFilters[filter.key])) {
      const defaultFilter = groupFilters.find((filter) => filter.default)
      if (defaultFilter) {
        updatedFilters[defaultFilter.key] = true
      }
    }
    setFilters(updatedFilters)
  }

  return (
    <FilterContainer>
      <Title>
        <H3>Filtros</H3>
      </Title>
      <SelectedFilters>
        {Object.keys(filters).map((key, index) => {
          if (key === 'periodo') {
            return filters[key].fecha_inicio !== '' ||
              filters[key].fecha_fin !== ''
              ? null
              : null
          }

          const tagLabel = findTagByKey(key)
          if (!tagLabel) {
            return null
          }

          return filters[key] ? (
            <FilterTag
              key={index}
              label={tagLabel}
              onRemove={() =>
                handleRemoveFilter(
                  Object.keys(filtersOptions).find((group) =>
                    filtersOptions[group].some((option) => option.key === key),
                  ),
                  key,
                )
              }
              filterValue={key}
            />
          ) : null
        })}
      </SelectedFilters>

      {Object.keys(filtersOptions).map((filterGroup, index) => (
        <FilterGroup key={index}>
          <H3>{filterGroup}</H3>
          <LabelContainer>
            {filtersOptions[filterGroup].map(({ key, name }) =>
              name === 'Período' ? (
                <DateRangePicker
                  key={index}
                  dateRange={filters['periodo']}
                  setDateRange={(value) => handleSelectFilter(key, value)}
                />
              ) : (
                <Label key={key}>
                  <Input
                    type="checkbox"
                    checked={
                      key === 'periodo'
                        ? filters[key].fecha_inicio !== '' ||
                          filters[key].fecha_fin !== ''
                        : filters[key] || false
                    }
                    onChange={() => handleSelectFilter(key)}
                    disabled={
                      (onlyOneOption && filters[key]) ||
                      (key === 'todos' && filters['todos'])
                    }
                  />
                  {capitalizeFirstLetter(reemplazarInterrogacionPorÑ(name))}
                </Label>
              ),
            )}
          </LabelContainer>
        </FilterGroup>
      ))}
    </FilterContainer>
  )
}
