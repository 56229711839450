import { createContext, useContext, useState } from 'react'
import { getLocalStorageItem } from 'utils/loadData'

const FiltersContext = createContext()

export const useFilters = () => useContext(FiltersContext)

const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState(() =>
    getLocalStorageItem(key, defaultValue),
  )

  const setLocalStorageState = (newState) => {
    localStorage.setItem(key, JSON.stringify(newState))
    setState(newState)
  }

  return [state, setLocalStorageState]
}

export const FiltersProvider = ({ children }) => {
  const defaultPedidosFilters = {
    todos: true,
    cronograma_actual: false,
    este_mes: false,
    mes_pasado: false,
    historico: true,
    todos_provedores: true,
    operación_directa: false,
    marcas_líderes: false,
    todos_estados: true,
    aprobado: false,
    facturado: false,
    en_preparacion: false,
    generado: false,
    confeccion: false,
    desaprobado: false,
    cancelado: false,
    no_consolidado: false,
    periodo: {
      fecha_inicio: '',
      fecha_fin: '',
    },
  }

  const [comprarFilters, setComprarFilters] = useLocalStorageState(
    'comprarFilters',
    {
      cronograma_actual: false,
      operación_directa: false,
      marcas_líderes: false,
      todos_provedores: true,
      directa_y_redistribucion: true,
    },
  )

  const [pedidosFilters, setPedidosFilters] = useLocalStorageState(
    'pedidosFilters',
    defaultPedidosFilters,
  )

  const onPedidosFiltersChange = (newFilters) => {
    setPedidosFilters((prevFilter) => {
      const filtersChanged =
        JSON.stringify(prevFilter) !== JSON.stringify(newFilters)
      if (filtersChanged) {
        localStorage.setItem('pedidosPageNumber', JSON.stringify(1))
      }
      return newFilters
    })
  }

  const resetPedidosFilters = () => {
    setPedidosFilters(defaultPedidosFilters)
  }

  const contextValues = {
    comprarFilters,
    setComprarFilters,
    onComprarFiltersChange: setComprarFilters,
    pedidosFilters,
    onPedidosFiltersChange,
    resetPedidosFilters,
  }

  return (
    <FiltersContext.Provider value={contextValues}>
      {children}
    </FiltersContext.Provider>
  )
}
