import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Trash2 } from 'feather-icons-react/build/IconComponents'
import HeaderPedidoConfirmado from 'pages/pedidos/components/HeaderComprobantes/HeaderPedidoConfirmado'
import { H4, P3 } from 'components/Typography'
import { Button } from 'components/index'
import PedidosConfirmadosTable from 'pages/pedidos/components/MisPedidos/PedidosConfirmadosTable'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePedidos } from 'contexts/PedidosContext'
import { useCronograma } from 'contexts/CronogramaContext'
import DeletePedidoModal from 'components/Modal/DeletePedidoModal'
import { useModal } from 'contexts/ModalContext'
import dayjs from 'dayjs'
import { showToast } from 'components/Notification/ToastNotification'

const WrapperAgrupacionPage = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  margin: 50px auto;
`

const CardHeader = styled.header`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral.gray}`};
  border-left: ${({ theme }) => `3px solid ${theme.colors.primary.darkBlue}`};
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 14px 44px;
`

const TableSection = styled.div`
  width: 100%;
`

const StyledButton = styled(Button)`
  text-decoration: none;
  align-items: center;
`

const WrapperTables = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 20px 40px;
`

const ButtonVolver = styled(Button)`
  padding: 8px 20px;
`

const EmptyTableData = styled(P3)`
  padding: 23px 30px;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
`

const DetallePedidosConfirmados = ({ isNoConfirmado = false }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location
  const {
    pedidoConfeccionado,
    getPedidoConfeccionado,
    cancelarConfirmacionCarrito,
  } = usePedidos()
  const { cronograma } = useCronograma()
  const { openModal, isModalOpen } = useModal()
  const [isDeletePedidoModalOpen, setIsDeletePedidoModalOpen] = useState(false)
  const [idProveedorPedidoToDelete, setIdProveedorPedidoPedidoToDelete] =
    useState()
  const [idPedidoToDelete, setIdPedidoToDelete] = useState()

  const handleDeletePedido = (idProveedor, idPedido) => {
    openModal()
    setIsDeletePedidoModalOpen(true)
    setIdProveedorPedidoPedidoToDelete(idProveedor)
    setIdPedidoToDelete(idPedido)
    showToast(`¡Se borraron los valores con éxito!`, 'validacion')
  }

  const afterClose = () => {
    setIsDeletePedidoModalOpen(false)
  }
  const redirectAfterDelete = () => navigate('/pedidos')

  useEffect(() => {
    if (state?.idCarrito && state?.idProveedor) {
      getPedidoConfeccionado(state.idCarrito, state.idProveedor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronograma])

  return (
    <WrapperAgrupacionPage>
      {pedidoConfeccionado && Object.keys(pedidoConfeccionado).length > 0 && (
        <TableSection>
          <HeaderPedidoConfirmado
            cabecera={pedidoConfeccionado.cabecera}
            totales={pedidoConfeccionado.totales}
            requisitos={pedidoConfeccionado.requisitos}
            fecha={dayjs(pedidoConfeccionado.fecha).format('DD/MM/YYYY')}
          />
          <WrapperTables>
            {pedidoConfeccionado.pedido[0] !== null ? (
              <>
                <CardHeader>
                  <H4>Pedido</H4>
                  {pedidoConfeccionado.id_cronograma ===
                  cronograma?.id_cronograma ? (
                    <StyledButton
                      text={'Borrar pedido'}
                      type={'tertiary'}
                      icon={<Trash2 size={14} />}
                      onClick={async () => {
                        handleDeletePedido(
                          pedidoConfeccionado.cabecera.condiciones.id,
                          state.idCarrito,
                        )
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </CardHeader>

                <PedidosConfirmadosTable
                  pedidos={pedidoConfeccionado.pedido}
                  totales={pedidoConfeccionado.totales}
                  cancelarConfirmacionCarrito={cancelarConfirmacionCarrito}
                  idCronograma={cronograma.id_cronograma}
                  idCronogramaPedido={pedidoConfeccionado.id_cronograma}
                  idProveedor={pedidoConfeccionado.cabecera.condiciones.id}
                  isNoConfirmado={isNoConfirmado}
                />
              </>
            ) : (
              <EmptyPage>
                <EmptyTableData>Error al recuperar datos</EmptyTableData>
                <ButtonVolver
                  onClick={() => navigate('/pedidos')}
                  type="secondary"
                  text="Volver"
                  size="small"
                />
              </EmptyPage>
            )}
          </WrapperTables>
        </TableSection>
      )}
      {isModalOpen && isDeletePedidoModalOpen && (
        <DeletePedidoModal
          idProveedor={idProveedorPedidoToDelete}
          idProducto={idPedidoToDelete}
          afterClose={afterClose}
          redirectAfterDelete={redirectAfterDelete}
        />
      )}
    </WrapperAgrupacionPage>
  )
}

export default DetallePedidosConfirmados
