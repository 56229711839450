import styled from 'styled-components'
import Card from 'components/Card/Card'
import { useAuth } from 'contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import {
  Calendar,
  Check,
  ShoppingBag,
} from 'feather-icons-react/build/IconComponents'
import { formatDate } from 'utils/textProcess'
import { useCronograma } from 'contexts/CronogramaContext'
import { totalArticulos } from 'pages/carrito/utils'
import { useCarrito } from 'contexts/CarritoContext'

const CardCarouselWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${({ isProveedor }) => (isProveedor ? '90%' : '100%')};
  min-height: 300px;
  gap: 45px;
  box-sizing: border-box;
  padding: 0 10%;

  @media (max-width: 1366px) {
    gap: 20px;
  }

  @media (max-width: 965px) {
    flex-direction: column;
    max-width: 900px;
  }

  @media (max-width: 440px) {
    padding: 0 10% 15%;
  }
`

const CardWrapper = styled.div`
  flex: 1;

  @media (max-width: 1366px) {
    width: 100%;
  }

  @media (max-width: 440px) {
    flex: 0;
  }
`

export const cardsData = (navigate, cronograma, carrito) => {
  return {
    Coordinadora: [
      {
        icon: <Calendar />,
        title: 'Habilitar',
        subTitle: `Disponible desde el ${formatDate(cronograma.fecha_inicio).slice(0, 5)}`,
        dateText: `Antes del ${formatDate(cronograma.fecha_fin).slice(0, 5)}`,
        buttonSection: [
          {
            text: 'Ir a habilitar',
            action: () => {
              navigate('/coordinacion')
            },
          },
        ],
      },
      {
        icon: <ShoppingBag />,
        title: 'Comprar',
        subTitle: `Tenés cargados ${totalArticulos(carrito?.carritos)} bultos`,
        dateText: `Antes del ${formatDate(cronograma.fecha_fin).slice(0, 5)}`,
        buttonSection: [
          {
            text: 'Ver carrito',
            action: () => {
              navigate('/mi-carrito')
            },
          },
          {
            text: 'Ir a comprar',
            action: () => {
              navigate('/comprar')
            },
          },
        ],
      },
      {
        icon: <Check />,
        title: 'Consolidar',
        subTitle: ` `,
        dateText: `Antes del ${formatDate(cronograma.fecha_fin).slice(0, 5)}`,
        buttonSection: [
          {
            text: 'Ir a Consolidar',
            action: () => {
              navigate('/coordinacion')
            },
          },
        ],
      },
    ],
    Proveedor: [
      {
        icon: '',
        title: 'Ver surtido',
        dateText: ``,
        subTitle: '',
        buttonSection: [
          { text: 'Ir a surtido', action: () => navigate('/mis-productos') },
        ],
      },
      {
        icon: '',
        title: 'Controlar pedidos',
        dateText: ``,
        subTitle: '',
        buttonSection: [
          { text: 'Ir a confirmar', action: () => navigate('/pedidos') },
        ],
      },
    ],
    Asociada: [
      {
        icon: <ShoppingBag />,
        title: 'Comprar',
        dateText: `Antes del ${formatDate(cronograma.fecha_fin).slice(0, 5)}`,
        subTitle: `Tenés cargados ${totalArticulos(carrito?.carritos)} bultos`,
        buttonSection: [
          {
            text: 'Ver carrito',
            action: () => {
              navigate('/mi-carrito')
            },
          },
          {
            text: 'Ir a comprar',
            action: () => {
              navigate('/comprar')
            },
          },
        ],
      },
      {
        type: 'secondary',
        icon: <Check />,
        title: 'Confirmar',
        subTitle: ` `,
        dateText: `Antes del ${formatDate(cronograma.fecha_fin).slice(0, 5)}`,
        buttonSection: [
          {
            text: 'Ir a confirmar',
            action: () => {
              navigate('/mi-carrito')
            },
          },
        ],
      },
    ],
    'Asociada Individual': [
      {
        icon: <ShoppingBag />,
        title: 'Comprar',
        dateText: `Antes del ${formatDate(cronograma.fecha_fin).slice(0, 5)}`,
        subTitle: `Tenés cargados ${totalArticulos(carrito?.carritos)} bultos`,
        buttonSection: [
          {
            text: 'Ver carrito',
            action: () => {
              navigate('/mi-carrito')
            },
          },
          {
            text: 'Ir a comprar',
            action: () => {
              navigate('/comprar')
            },
          },
        ],
      },
      {
        icon: <Check />,
        title: 'Confirmar',
        subTitle: ` `,
        dateText: `Antes del ${formatDate(cronograma.fecha_fin).slice(0, 5)}`,
        buttonSection: [
          {
            text: 'Ir a confirmar',
            action: () => {
              navigate('/mi-carrito')
            },
          },
        ],
      },
    ],
  }
}

const CardCarousel = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { cronograma } = useCronograma()
  const { carrito } = useCarrito()
  return (
    <CardCarouselWrapper isProveedor={user.rol.id_rol === '5'}>
      {user.rol &&
        cardsData(navigate, cronograma, carrito)[user.rol.nombre].map(
          (card, index) => (
            <CardWrapper key={index}>
              <Card key={index} {...card} />
            </CardWrapper>
          ),
        )}
    </CardCarouselWrapper>
  )
}

export default CardCarousel
