import React, { useState } from 'react'
import styled from 'styled-components'

import { Col, Row } from 'components/layout/FlexComponents'
import { H2, P2 } from 'components/Typography'
import { Button } from 'components/index'
import { Download } from 'feather-icons-react/build/IconComponents'
import { replaceText } from 'utils/textProcess'
import { useNavigate } from 'react-router-dom'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import DescriptionRequerimientosProveedor from './DescriptionRequerimientosProveedor'
import Select from 'components/Select/Select'
import { useArticulos } from 'contexts/ArticulosContext'
import { useCronograma } from 'contexts/CronogramaContext'
import RotatingLoader from 'components/Loading/RotatingLoader'
import { useAuth } from 'contexts/AuthContext'
import { useProveedor } from 'contexts/ProveedorContext'
import { useCarrito } from 'contexts/CarritoContext'
import ProveedorService from 'services/proveedor'
import { DATE_OPTIONS } from 'utils/constants'
import DropdownNotification from 'components/Notification/DropdownNotification'

const Header = styled(Col)`
  border-radius: 15px 15px 0 0;
  padding: 8px 0;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 440px) {
    gap: 10px;
  }
`

const ProveedorItem = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};

  @media (max-width: 440px) {
    flex-direction: column;
    padding: 8px 14px;
  }
`

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;

  @media (max-width: 440px) {
    width: 80%;
  }
`

const WrapperRequerimientos = styled.div`
  flex: 1;
  padding: 0 20px 20px;
`

const DropdownNotificationCustom = styled(DropdownNotification)`
  margin: 0 20px 20px;
`

const WrapperZona = styled.div`
  padding: 25px 20px 20px 20px;
`

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  @media (max-width: 440px) {
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  text-align: start;
`

const CustomLoading = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LastPriceDate = styled(P2)`
  margin-left: 24px;
`

const HeaderSurtido = ({
  proveedor,
  puedePedir,
  motivo,
  lastPrice,
  zonasProveedor,
  articulosVacios,
}) => {
  const { user, token } = useAuth()
  const navigate = useNavigate()
  const { getArticulos } = useArticulos()
  const { getProveedor } = useProveedor()
  const { carritoAgrupado } = useCarrito()
  const { cronograma } = useCronograma()
  const { bestZona, mejorProveedorZona, cambiarZona } = useCronograma()
  const [selectedZona, setSelectedZona] = useState(proveedor.zonaDefault)

  const navigateToProveedorPage = async (nombreProveedor) => {
    navigate(`/detalle-proveedor/${nombreProveedor}`, {
      state: { idProveedor: proveedor.id },
    })
  }

  const onClickDescargarSurtido = async () => {
    const csvData = await ProveedorService.obtenerCsvSurtidoProveedor({
      idCronograma: cronograma.id_cronograma,
      idProveedor: proveedor.id,
      idCarrito: carritoAgrupado.cabecera.id,
      token,
    })

    const url = window.URL.createObjectURL(
      new Blob([csvData], { type: 'text/csv' }),
    )
    const fechaDescarga = new Date()
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute(
      'download',
      `Surtido_${replaceText(proveedor.nombre_comercial, ' ', '-')}_${replaceText(fechaDescarga.toLocaleDateString('es', DATE_OPTIONS), '_', '-')}.csv`,
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <Header>
      <ProveedorItem>
        <ProveedoresItem key={proveedor.nombre_comercial}>
          <H2>{proveedor.nombre_comercial}</H2>
        </ProveedoresItem>
        <ButtonSection>
          <Button
            type="secondary"
            text="Datos proveedor"
            onClick={() =>
              navigateToProveedorPage(
                replaceText(proveedor.nombre_comercial, ' ', '-'),
              )
            }
          />
          <Button
            icon={<Download size={14} />}
            type="secondary"
            text="Descargar Surtido"
            onClick={onClickDescargarSurtido}
            disabled={articulosVacios}
          />
        </ButtonSection>
      </ProveedorItem>
      {lastPrice && (
        <LastPriceDate>
          Última actualización de precios {lastPrice}
        </LastPriceDate>
      )}
      {proveedor.requerimientos && (
        <ExpandableTables
          openTables={['Información']}
          items={[
            {
              title: 'Información',
              component: (
                <Wrapper>
                  <WrapperInfo>
                    <WrapperRequerimientos>
                      <DescriptionRequerimientosProveedor
                        requerimientos={proveedor.requerimientos}
                      />
                    </WrapperRequerimientos>
                    {(user.rol.id_rol === '1' || user.rol.id_rol === '3') && (
                      <WrapperZona>
                        <StyledLabel>Cambiar zona</StyledLabel>
                        {zonasProveedor === undefined ? (
                          <CustomLoading>
                            <RotatingLoader />
                          </CustomLoading>
                        ) : zonasProveedor === null ? (
                          <P2>No hay zonas</P2>
                        ) : Object.keys(zonasProveedor).length > 0 ? (
                          <Select
                            id="Zona"
                            options={zonasProveedor.reduce((acc, item) => {
                              acc[item.id] = item.descripcion
                              return acc
                            }, {})}
                            disabled={
                              proveedor.tipo_proveedor === '1' || !puedePedir
                            }
                            bestZona={bestZona ? bestZona[proveedor.id] : false}
                            selectedOption={
                              // proveedor.tipo_proveedor === '1'
                              //   ? 1
                              //   :
                              selectedZona
                            }
                            toggleOptionsAction={() =>
                              mejorProveedorZona(proveedor.id)
                            }
                            onChange={async (zonaId) => {
                              await cambiarZona(
                                zonaId,
                                proveedor.id,
                                carritoAgrupado.cabecera.id,
                              )
                              setSelectedZona(zonaId)
                              await getArticulos(proveedor.id)
                              await getProveedor(proveedor.id)
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </WrapperZona>
                    )}
                  </WrapperInfo>
                  {motivo.includes(
                    'Este usuario ya posee un carrito confeccionado para este proveedor',
                  ) ? (
                    <DropdownNotificationCustom
                      text="Ya tenés un pedido realizado con este proveedor"
                      type="notificacion"
                      action={() => navigate(`/pedidos/`)}
                      actionText="Ver pedidos"
                    />
                  ) : user.rol.id_rol === '2' ? (
                    <></>
                  ) : proveedor.tipo_proveedor === '1' ? (
                    <DropdownNotificationCustom
                      text="No está disponible el cambio de zonas en las Marcas Líderes."
                      type="notificacion"
                    />
                  ) : (
                    puedePedir && (
                      <DropdownNotificationCustom
                        text="Las zonas de reparto con mejores precios aparecerán con una estrella amarilla en el menú desplegable"
                        type="notificacion"
                      />
                    )
                  )}
                </Wrapper>
              ),
            },
          ]}
        />
      )}
    </Header>
  )
}

export default HeaderSurtido
